import styled from 'styled-components';
import tw from 'tailwind.macro';

export const FormInfoBlock = styled.div`
  ${tw`flex flex-col flex-wrap my-4 mx-3 bg-white p-4 rounded-lg border bg-gray-100 border-gray-300`};
  ${({ center }) => center && tw`items-center`};
`;

export const Icon = styled.span`
  ${tw`flex items-center justify-center w-10 h-10 border bg-white border-purple-900 hover:border-yellow-600 rounded-full mb-2`};
`;

export const Wrapper = styled.div`
  ${({ center }) => center && tw`text-center`};
`;

export const Title = styled.h3`
  ${tw`text-lg mt-1 font-semibold`};
`;

export const Content = styled.p`
  ${tw`mt-1`};
`;

export const Link = styled.a`
  ${tw`mt-4 text-purple-900 justify-start`};
`;

export const IconLink = styled.a`
  ${tw`mt-1 text-gray-900 justify-start`};
`;
