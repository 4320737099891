import styled from 'styled-components';
import tw from 'tailwind.macro';
import { motion } from 'framer-motion';

export const Button = motion.custom(styled.button`
  outline: none !important;
  ${tw` mt-5 mx-auto py-2 px-8 rounded-full border hover:border-yellow-600 border-purple-400 text-white justify-center`};

  ${({ primary }) => (primary ? tw`bg-purple-900 self-center` : tw`text-purple-600`)};

  ${({ block }) => block && tw`w-full`};
`);


export const ContactInfo = styled.div`
  ${tw` my-4 mx-auto bg-white p-5 w-full rounded-lg border bg-gray-100 border-gray-300 `};
`;

export const Form = styled.form`
${tw`m-5 `};
`;

export const Content = styled.div`
  ${tw`mt-1 flex flex-row `};
`;

export const Label = styled.label`
  ${tw`text-xl m-1 w-full flex flex-row flex-wrap`};
`;

export const MessageArea = styled.textarea`
  ${tw`mt-1 flex flex-row w-full border-gray-300 border`};
`;

export const Header = styled.h3`
  ${tw` mt-1 font-bold text-xl text-center`};
`;

export const Input = styled.input`
  ${tw`w-full border-gray-300 border`};
`;