import React from 'react';
import { navigate } from 'gatsby-link';
import * as Styled from './styles';
import Recaptcha from 'react-google-recaptcha';
import './background.css';

const RECAPTCHA_KEY = process.env.GATSBY_APP_SITE_RECAPTCHA_KEY
if (typeof RECAPTCHA_KEY === 'undefined') {
  throw new Error(`
  Env var GATSBY_APP_SITE_RECAPTCHA_KEY is undefined! 
  You probably forget to set it in your Netlify build environment variables.
  `)
}

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default function Contact() {
  const [state, setState] = React.useState({});
  const recaptchaRef = React.createRef();

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  };

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target;
    const recaptchaValue = recaptchaRef.current.getValue();
    
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'), 'g-recaptcha-response': recaptchaValue,
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  return (
    
<Styled.ContactInfo>
    <Styled.Header>Contact Rob to get Started</Styled.Header>
      <Styled.Form 
        name="Enquiry-form"
        method="post"
        // action="/thanks/"
        action="#"
        data-netlify="true"
        data-netlify-recaptcha="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="Enquiry-form" />
        <p hidden>
          <label>
            Don’t fill this out: <input name="bot-field" onChange={handleChange} />
          </label>
        </p>
        <Styled.Content>
          <Styled.Label>
            First Name:
            <br />
            <Styled.Input type="text" name="first-name" onChange={handleChange}/>
          </Styled.Label>
          <Styled.Label>
            Last Name:
            <br />
            <Styled.Input type="text" name="last-name" onChange={handleChange} />
          </Styled.Label>
        </Styled.Content>
        
        <Styled.Content>
          <Styled.Label>
            Contact Email:
            <br />
            <Styled.Input type="email" name="email" onChange={handleChange} />
          </Styled.Label>
          <Styled.Label>
            Company Name:
            <br />
            <Styled.Input type="company-name" name="company-name" onChange={handleChange} />
          </Styled.Label>
        </Styled.Content>
        <Styled.Content>
        <Styled.Label>
            Message:
            <br />
            <Styled.MessageArea name="message" onChange={handleChange} />
          </Styled.Label>
        </Styled.Content>
        <Recaptcha ref={recaptchaRef} sitekey={RECAPTCHA_KEY} class="g-recaptcha"/>
        <Styled.Content>
          <Styled.Button primary id= "button" type="submit">Send</Styled.Button>
        </Styled.Content>
      </Styled.Form>
      </Styled.ContactInfo>
      
  )
}