import React from 'react';
import PropTypes from 'prop-types';
import Header from 'components/Header';
import Container from 'components/ui/Container';
import TitleSectionCV from 'components/ui/TitleSectionCV';
import './cv-background.css';
import * as Styled from './styles';


const Banner = ({ title, subtitle, content, linkText }) => (
  <Styled.Banner id="cvBackdrop">
    <Header />
    <Container section>
      <TitleSectionCV title={title} subtitle={subtitle} />
      <Styled.Content>{content}</Styled.Content>
    </Container>
  </Styled.Banner>
);

Banner.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.any.isRequired,
  linkTo: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired
};

export default Banner;
