import React from 'react';

import Container from 'components/ui/Container';
import Icon from 'components/ui/Icon';
import Logo from '../Header/Logo/index.js';
import * as Styled from './styles';
import { faFacebookF, faGithub, faMediumM } from '@fortawesome/free-brands-svg-icons';

const Footer = () => (
  <Styled.Footer>
    <Logo />
    <Container>
      <Styled.Title>Lets Get Social</Styled.Title>
      
      <Styled.Links>
        <Styled.Link href="https://www.facebook.com/Fall-Development-Ltd-108871427712373/" rel="noreferrer noopener" target="_blank">
          <Styled.Icon>
            <Icon icon={faFacebookF} />
          </Styled.Icon>
        </Styled.Link>
        <Styled.Link href="/" rel="noreferrer noopener" target="_blank">
          <Styled.Icon>
            <Icon icon={faMediumM} />
          </Styled.Icon>
        </Styled.Link>
        <Styled.Link href="/" rel="noreferrer noopener" target="_blank">
          <Styled.Icon>
            <Icon icon={faGithub} />
          </Styled.Icon>
        </Styled.Link>
      </Styled.Links>
    </Container>

    <Styled.Copyright>Copyright 2020 -<a href="https://www.fall-development.com">Fall Development</a></Styled.Copyright>
    
  </Styled.Footer>
);

export default Footer;
