import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/ui/Icon';
import './styles.css';
import * as Styled from './styles';

const FormInfoBlock = ({ icon, title, content, center }) => (
  <Styled.FormInfoBlock center={center} id="services">
    <Styled.IconLink href="/contact/">
    <Styled.Icon>
      <Icon icon={icon} />
    </Styled.Icon>
    </Styled.IconLink>
    <Styled.Wrapper center={center}>
      <Styled.Title>{title}</Styled.Title>
      <Styled.Content>{content}</Styled.Content>
    </Styled.Wrapper>
  </Styled.FormInfoBlock>
);

FormInfoBlock.propTypes = {
  center: PropTypes.bool,
  title: PropTypes.string.isRequired,
  content: PropTypes.any.isRequired,
  icon: PropTypes.string.isRequired
};

export default FormInfoBlock;
