import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Footer = styled.footer`
  ${tw`border-t border-gray-200 py-4 bg-black`};
`;

export const Links = styled.div`
  ${tw`flex items-center justify-center w-full`};

  a {
    ${tw`text-white hover:text-yellow-600 mx-2`};
  }
`;

export const Link = styled.a`
  ${tw`text-indigo-900 hover:text-indigo-600 mx-2`};
`;

export const Icon = styled.span`
  ${tw`flex items-center justify-center w-10 text-white h-10 border bg-purple-900 border-purple-600 hover:border-yellow-600 rounded-full mb-2`};
`;

export const Title = styled.h2`
  ${tw`flex items-center justify-center text-white m-auto py-2 text-2xl`};
`;


export const Copyright = styled.h2`
  ${tw`flex items-center justify-center text-white m-auto py-2 text-l`};

  a {
    ${tw`text-white hover:text-yellow-600 mx-2 font-sans`};
  }
`;



