import styled from 'styled-components';
import tw from 'tailwind.macro';
import { Container } from 'components/ui/Container/styles';

export const Header = styled.header`
  ${tw`bg-purple-900 sticky bg-transparent -mb-px`};
`;

export const Wrapper = styled(Container)`
  ${tw`items-center sticky justify-between`};
`;
