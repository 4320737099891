import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/ui/Button';
import * as Styled from './styles';
import downloadFile from '../../../assets/downloadables/CV.pdf';

const TitleSection = ({ center, title, subtitle, linkTo }) => (
  <Styled.TitleSection>
    {subtitle && <Styled.SubTitle center={center}>{title}</Styled.SubTitle>}
    <Styled.Title center={center}>{subtitle}</Styled.Title>
    <a href={downloadFile}>
      <Button primary>Download My CV</Button>
    </a>
  </Styled.TitleSection>
);

TitleSection.propTypes = {
  center: PropTypes.bool,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  linkTo: PropTypes.string
};

export default TitleSection;
