import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import CVBanner from 'components/ui/CVBanner';

const CVHero = () => {
  const { markdownRemark } = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { category: { eq: "Cir" } }) {
        frontmatter {
          title
          subtitle
          content
          linkTo
          linkText
        }
      }
    }
  `);

  const cvBanner = markdownRemark.frontmatter;

  return (
    <CVBanner
      title={cvBanner.title}
      subtitle={cvBanner.subtitle}
      content={cvBanner.content}
      linkTo={cvBanner.linkTo}
      linkText={cvBanner.linkText}
    />
  );
};

CVHero.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  content: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired
};

export default CVHero;
