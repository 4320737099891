import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Banner = styled.section`
  ${tw` border-b border-indigo-100 w-full `};
`;

export const Content = styled.p`
  ${tw`mb-8 text-white`};
`;
