import React from 'react';
import CV from 'components/CV';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import ContactInfo from 'components/ContactInfo';


const aboutPage: React.FC = () => (
  <Layout>
    <SEO title="CV" />
    <CV />
    <ContactInfo />
  </Layout>
);

export default aboutPage;
