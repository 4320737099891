import React from 'react';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faLaptopCode,
  faDrawPolygon,
  faEdit,
  faBullhorn,
  faMapMarkerAlt,
  faPhone,
  faPaperPlane,
  faMobileAlt,
  faFileCode,
  faSync,
  faCogs,
  faBrain,
  faRobot,
  faWrench,
  faChartLine
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* add any additional icon to the library */
library.add(
  fab,
  faFileCode,
  faLaptopCode,
  faDrawPolygon,
  faEdit,
  faEdit,
  faBullhorn,
  faMapMarkerAlt,
  faPhone,
  faPaperPlane,
  faMobileAlt,
  faSync,
  faCogs,
  faBrain,
  faRobot,
  faWrench,
  faChartLine
);

const Icon = ({ ...props }) => <FontAwesomeIcon {...props} />;

export default Icon;
